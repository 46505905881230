import { FC } from 'react'
import { ToastAlertType } from '../../contexts/toasts'
import ToastAlert from '../atoms/ToastAlert'

interface Props {
  toasts: ToastAlertType[]
  hideToast: (id: string) => void
}

const ToastAlerts: FC<Props> = ({ toasts, hideToast }) => {
  return (
    <>
      {toasts.map((toast, index) => (
        <ToastAlert
          key={`${toast.id}-${index}`}
          toast={toast}
          show={true}
          hideToast={hideToast}
        />
      ))}
    </>
  )
}

export default ToastAlerts
