import { isJwtError, isTokenExpired } from './errors'

export enum TOKEN_STORAGE_KEY {
  AUTH = 'auth',
  REFRESH = 'refresh',
  CSRF = 'csrf',
}

export const getTokens = () => ({
  auth:
    localStorage.getItem(TOKEN_STORAGE_KEY.AUTH) ||
    sessionStorage.getItem(TOKEN_STORAGE_KEY.AUTH),
  refresh:
    localStorage.getItem(TOKEN_STORAGE_KEY.REFRESH) ||
    sessionStorage.getItem(TOKEN_STORAGE_KEY.REFRESH),
  csrf:
    localStorage.getItem(TOKEN_STORAGE_KEY.CSRF) ||
    sessionStorage.getItem(TOKEN_STORAGE_KEY.CSRF),
})

export const setTokens = (
  auth: string,
  refresh: string,
  csrf: string,
  persist: boolean,
) => {
  if (persist) {
    localStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
    localStorage.setItem(TOKEN_STORAGE_KEY.REFRESH, refresh)
    localStorage.setItem(TOKEN_STORAGE_KEY.CSRF, csrf)
  } else {
    sessionStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
    sessionStorage.setItem(TOKEN_STORAGE_KEY.REFRESH, refresh)
    sessionStorage.setItem(TOKEN_STORAGE_KEY.CSRF, csrf)
  }
}

export const setAuthToken = (auth: string, persist: boolean) => {
  if (persist) {
    localStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
  } else {
    sessionStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
  }
}

export const removeTokens = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY.AUTH)
  localStorage.removeItem(TOKEN_STORAGE_KEY.REFRESH)
  localStorage.removeItem(TOKEN_STORAGE_KEY.CSRF)
  sessionStorage.removeItem(TOKEN_STORAGE_KEY.AUTH)
  sessionStorage.removeItem(TOKEN_STORAGE_KEY.REFRESH)
  sessionStorage.removeItem(TOKEN_STORAGE_KEY.CSRF)
}
