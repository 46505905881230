import React, { useContext } from 'react'

import { UserContext } from './'
import { useAuthProvider } from './hooks/useAuthProvider'
import { getTokens } from './utils'

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const authProvider = useAuthProvider()

  return (
    <UserContext.Provider value={authProvider}>{children}</UserContext.Provider>
  )
}

export const useAuth = () => {
  const user = useContext(UserContext)
  const isAuthenticated = !!user.user

  return {
    hasToken: !!getTokens().auth,
    isAuthenticated,
    // tokenAuthLoading: user.tokenAuthLoading,
    // tokenVerifyLoading: user.tokenVerifyLoading,
    user: user.user,
  }
}

export default AuthProvider
