import { Fragment, FC } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CogIcon,
  ChartPieIcon,
  ArrowPathIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline'
import { NavLink, Link } from 'react-router-dom'
import useUser from '../../hooks/useUser'

import logo from '../../media/logo-svg-full.svg'

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  // {
  //   name: 'Device Settings',
  //   href: '/device-settings',
  //   icon: AdjustmentsHorizontalIcon,
  // },
  { name: 'Reporting', href: '/reporting', icon: ChartPieIcon },
  { name: 'Scanning', href: '/device-scanning', icon: ArrowPathIcon },
]

interface Props {
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
}

const Sidebar: FC<Props> = ({ sidebarOpen, setSidebarOpen }) => {
  const { logout } = useUser()

  const secondaryNavigation: {
    name: string
    href?: string
    onClick?: () => void
    icon: any
  }[] = [
    { name: 'Configuration', href: '/config', icon: CogIcon },
    { name: 'Help', href: '/help', icon: QuestionMarkCircleIcon },
    { name: 'Privacy', href: '/privacy', icon: ShieldCheckIcon },
    {
      name: 'Logout',
      href: '#',
      onClick: logout,
      icon: ArrowRightOnRectangleIcon,
    },
  ]

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-3 pb-4 bg-secondary">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={logo} alt="Logo" />
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full divide-y divide-secondary-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-white hover:text-white hover:bg-secondary-800 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          : 'text-secondary-100 hover:text-white hover:bg-secondary-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      }
                    >
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-secondary-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="mt-6 pt-6">
                  <div className="px-2 space-y-1">
                    {secondaryNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-secondary-100 hover:text-white hover:bg-secondary-600"
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 text-secondary-200"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow bg-primary-900 pt-2 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-10 w-auto" src={logo} alt="Logo" />
            </div>
            <nav
              className="mt-5 flex-1 flex flex-col divide-y divide-gray-400 overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-white hover:text-white hover:bg-primary-700 bg-primary-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                        : 'text-white hover:text-white hover:bg-primary-700 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                    }
                  >
                    <item.icon
                      className="mr-4 flex-shrink-0 h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={item.onClick}
                      className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white hover:text-white hover:bg-primary-700"
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
