import { Fragment, FC, useState, useEffect, PropsWithChildren } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { Menu, Transition } from '@headlessui/react'
import { BellIcon, Bars3Icon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import useUser from '../../hooks/useUser'
import { useToastAlert } from '../../contexts/toasts'
import ToastAlerts from '../../uiComponents/molecules/ToastAlerts'

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const { toasts, hideToast } = useToastAlert()

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  useEffect(() => {
    setSidebarOpen(false)
  }, [location.pathname])

  //const { user } = useUser()
  const user = {
    avatar: {
      url: 'https://images.unsplash.com/photo-1616489953149-8c8b0b0b5b1c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    },
    firstName: 'Michael',
    lastName: 'Scott',
  }

  const showPanels =
    !!user &&
    !['/login', '/sign-up', '/forgot-password', '/reset-password'].includes(
      location.pathname,
    )

  const layoutClass = classNames({
    'h-screen overflow-hidden': true,
    'bg-gray-200': showPanels,
    'bg-secondary-900': !showPanels,
    flex: showPanels,
  })

  return (
    <div className={layoutClass}>
      <ToastAlerts toasts={toasts} hideToast={hideToast} />
      {showPanels && (
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}
      <div className="flex-1 overflow-auto focus:outline-none">
        {showPanels && (
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-primary-900 border-b border-gray-200 lg:border-none">
            <button
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex-1 px-4 flex justify-end sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
              <div className="ml-4 flex items-center">
                {/* Profile dropdown
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs rounded-full flex items-center text-sm lg:p-2 lg:rounded-md">
                          <span className="hidden ml-3 text-white text-sm font-medium lg:block">
                            <span className="sr-only">Open user menu for </span>
                            Devices
                          </span>
                          <ChevronDownIcon
                            className="hidden flex-shrink-0 ml-2 h-5 w-5 text-gray-400 lg:block"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                role="button"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                System
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                role="button"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                Device 1
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                role="button"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                Device 2
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu> */}
              </div>
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default Layout
