interface IJWTToken {
  email: string
  iat: number
  exp: number
}

export function decodeJwtToken(token: string): IJWTToken | undefined {
  try {
    const data = atob(token.split('.')[1])

    return JSON.parse(data)
  } catch {
    return
  }
}

export function isJwtTokenExpired(token: string): boolean {
  const info = decodeJwtToken(token)

  return !(info && info.exp >= Date.now() / 1000)
}
