import { FC, Suspense, lazy } from 'react'
import Layout from './components/Layout/Layout'
import { Routes as RouterRoutes, Route } from 'react-router-dom'
import Skeleton from './uiComponents/atoms/Skeleton'

import LoginPage from './pages/Auth/LoginPage'
import SignUpPage from './pages/Auth/SignUpPage'
import RequestPasswordResetPage from './pages/Auth/RequestPasswordResetPage'
import PasswordResetPage from './pages/Auth/PasswordResetPage'
import RequireAuth from './auth/RequireAuth'

const DashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-page" */ './pages/Dashboard/DashboardPage'
    ),
)

const DeviceSettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "device-settings-page" */ './pages/DeviceSettings/DeviceSettingsPage'
    ),
)

const ReportingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "reporting-page" */ './pages/Reporting/ReportingPage'
    ),
)

const ScanningPage = lazy(
  () =>
    import(
      /* webpackChunkName: "reporting-page" */ './pages/Scanning/ScanningPage'
    ),
)

const ConfigPage = lazy(
  () =>
    import(/* webpackChunkName: "config-page" */ './pages/Config/ConfigPage'),
)

const Routes: FC = () => {
  return (
    <Layout>
      <Suspense fallback={<Skeleton />}>
        <RouterRoutes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route
            path="/forgot-password"
            element={<RequestPasswordResetPage />}
          />
          <Route path="/reset-password" element={<PasswordResetPage />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path="/device-settings"
            element={
              <RequireAuth>
                <DeviceSettingsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/reporting"
            element={
              <RequireAuth>
                <ReportingPage />
              </RequireAuth>
            }
          />
          <Route
            path="/device-scanning"
            element={
              <RequireAuth>
                <ScanningPage />
              </RequireAuth>
            }
          />
          <Route
            path="/config"
            element={
              <RequireAuth>
                <ConfigPage />
              </RequireAuth>
            }
          />
        </RouterRoutes>
      </Suspense>
    </Layout>
  )
}

export default Routes
