import React, { FC, PropsWithChildren, useState, useEffect } from 'react'

export type ToastAlertType = {
  id: string
  title: string
  message: string
  type: 'success' | 'error' | 'warning' | 'info'
}

interface ToastAlertContextInterface {
  showToast: (toast: ToastAlertType) => void
  hideToast: (id: string) => void
  toasts: ToastAlertType[]
}

const ToastAlertContext =
  React.createContext<ToastAlertContextInterface>(undefined)

export const useToastAlert = () => {
  const context = React.useContext(ToastAlertContext)
  if (context === undefined) {
    throw new Error('useToastAlert must be used within a ToastAlertContext')
  }
  return context
}

export const ToastAlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastAlertType[]>([])

  const showToast = (toast: ToastAlertType) => {
    setToasts([...toasts, toast])
  }

  const hideToast = (id: string) => {
    setToasts(toasts.filter((toast) => toast.id !== id))
  }

  useEffect(() => {
    let timeout: any

    if (toasts.length > 0) {
      timeout = setTimeout(() => {
        setToasts([])
      }, 5000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [toasts])

  return (
    <ToastAlertContext.Provider
      value={{
        showToast,
        hideToast,
        toasts,
      }}
    >
      {children}
    </ToastAlertContext.Provider>
  )
}
