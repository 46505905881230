import React from 'react'

interface UserContextInterface {
  login: (username: string, password: string) => Promise<any>
  loginByToken: (auth: string, csrf: string, user: any) => void
  logout: () => void
  // tokenAuthLoading: boolean
  // tokenRefresh: () => Promise<boolean>
  // tokenVerifyLoading: boolean
  user?: any
}

export const UserContext = React.createContext<UserContextInterface>({
  login: undefined,
  loginByToken: undefined,
  logout: undefined,
  // tokenAuthLoading: false,
  // tokenRefresh: undefined,
  // tokenVerifyLoading: false,
})

export * from './utils'
