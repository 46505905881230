import { FC, useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ToastAlertType } from '../../contexts/toasts'

interface Props {
  show: boolean
  hideToast: (id: string) => void
  toast: ToastAlertType
}

const ToastAlert: FC<Props> = ({ show, hideToast, toast }) => {
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    setShowAlert(show)
  }, [show])

  return (
    <div
      aria-live="assertive"
      className="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={showAlert}
          as={Fragment}
          enter="transform ease-out duration-500 transition"
          enterFrom="translate-y-2 sm:translate-y-0 sm:translate-x-10"
          enterTo="translate-y-0 sm:translate-x-0"
          leave="transition ease-in duration-100"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {toast.title}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">{toast.message}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={() => {
                      hideToast(toast.id)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default ToastAlert
