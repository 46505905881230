import { MutableRefObject, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_ENDPOINT } from '../../config/api'
import { useRequest } from '../../hooks/useRequest'
import { removeTokens, setTokens } from '../utils'

export interface UseAuthProvider {
  logout: () => void
  tokenAuthLoading: boolean
  tokenRefresh: () => Promise<boolean>
  tokenVerifyLoading: boolean
  user?: any
  autologinPromise?: MutableRefObject<Promise<any>>
}

export function useAuthProvider() {
  const [userContext, setUserContext] = useState<undefined | any>(undefined)

  const { doRequest } = useRequest()
  const navigate = useNavigate()

  const logout = () => {
    setUserContext(undefined)
    removeTokens()

    onLogin()
  }

  const onLogin = () => {
    navigate('/')
  }

  const login = async (email: string, password: string) => {
    const result = await doRequest(`${API_ENDPOINT}/login`, {
      method: 'POST',
      data: { email, password },
    })

    if (result?.data) {
      const user = result.data.user

      setUserContext(user)
      if (user) {
        setTokens(
          result.data.token,
          result.data.refreshToken,
          result.data.csrfToken,
          true,
        )
      }

      onLogin()
    }

    return result
  }

  const loginByToken = (auth: string, refresh: string, user: any) => {
    setUserContext(user)
    setTokens(auth, refresh, null, true)
  }

  return {
    login,
    loginByToken,
    logout,
    user: userContext,
  }
}
