import { BrowserRouter } from 'react-router-dom'

import AuthProvider from './auth/AuthProvider'
import Routes from './routes'
import { ToastAlertProvider } from './contexts/toasts'

function App() {
  return (
    <ToastAlertProvider>
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </ToastAlertProvider>
  )
}

export default App
