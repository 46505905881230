import { getTokens, removeTokens, setAuthToken } from '../auth/utils'
import { API_ENDPOINT } from '../config/api'
import { isJwtTokenExpired } from '../utils/jwt'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export type RequestOptions = {
  method: HttpMethod
  data?: any
  accessToken?: string
  controller?: AbortController
  requiresAuth?: boolean
}

export interface IAuthenticationTokenHandlerResult {
  authenticated: boolean

  doAuthenticatedRequest<T = any>(
    url: string,
    { method, data, controller }: RequestOptions,
  ): Promise<T>

  createAuthenticationToken(): Promise<{
    access_token: string
    refresh_token: string
  }>
}

const sendRequest = async (
  url: string,
  { method, accessToken, data, controller }: RequestOptions,
) => {
  return fetch(url, {
    method,
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...(data ? { 'Content-Type': 'application/json' } : {}),
    },
    body: data ? JSON.stringify(data) : undefined,
    signal: controller?.signal,
  })
}

export function useAuthenticationTokenHandler(): IAuthenticationTokenHandlerResult {
  const { auth, refresh } = getTokens()

  const authenticated = !!auth

  const refreshAccessToken = async (tokens: {
    access_token: string
    refresh_token: string
  }): Promise<string> => {
    const response = await fetch(`${API_ENDPOINT}/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: tokens.refresh_token,
        accessToken: tokens.access_token,
      }),
    })

    const result = await response.json()

    if (response.status === 400) {
      // Reset session
      removeTokens()

      throw new Error('Failed to refresh JWT token')
    }

    if (result.data.token) {
      // store session
      setAuthToken(result.data.token, true)

      return result.data.token
    }

    throw new Error('Failed to refresh JWT token')
  }

  const createAuthenticationToken = async (): Promise<{
    access_token: string
    refresh_token: string
  }> => {
    const result = await fetch(`${API_ENDPOINT}/authentication`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: '',
      }),
    }).then(async (res) => res.json())

    if (result.success) {
      const data = {
        access_token: result.data.access_token,
        refresh_token: result.data.refresh_token,
      }

      // store session

      return data
    }

    throw new Error(result.message)
  }

  const doAuthenticatedRequest = async (
    url: string,
    { method, data, controller }: RequestOptions,
  ) => {
    let requestToken: { access_token: string; refresh_token: string } = {
      access_token: auth,
      refresh_token: refresh,
    }

    if (!authenticated) {
      requestToken = await createAuthenticationToken()
    }

    if (isJwtTokenExpired(requestToken.access_token)) {
      requestToken.access_token = await refreshAccessToken(requestToken)
    }

    if (!requestToken.access_token || !requestToken.refresh_token) {
      throw new Error('No authentication tokens provided!')
    }

    let resp = await sendRequest(url, {
      method,
      accessToken: requestToken.access_token,
      data,
      controller,
    })

    if (resp.status === 401) {
      requestToken.access_token = await refreshAccessToken(requestToken)

      resp = await sendRequest(url, {
        method,
        accessToken: requestToken.access_token,
        data,
        controller,
      })
    }

    controller = undefined

    return await resp.json()
  }

  return { authenticated, doAuthenticatedRequest, createAuthenticationToken }
}

export const useRequest = () => {
  const { authenticated, doAuthenticatedRequest } =
    useAuthenticationTokenHandler()

  function doRequest<T = any>(
    url: string,
    { method = 'GET', data, controller, requiresAuth = false }: RequestOptions,
    cb: (res: T) => void,
  ): () => void
  function doRequest<T = any>(
    url: string,
    { method = 'GET', data, controller, requiresAuth = false }: RequestOptions,
  ): Promise<T>
  function doRequest<T = any>(
    url: string,
    { method = 'GET', data, controller, requiresAuth = false }: RequestOptions,
    cb?: (res: T) => void,
  ): Promise<T> | (() => void) {
    if (requiresAuth) {
      if (!authenticated) {
        throw new Error('User not authenticated!')
      }

      if (cb) {
        if (!controller) {
          controller = new AbortController()
        }

        doAuthenticatedRequest(url, { method, data, controller })
          .then((res: any) => cb(res))
          .catch((error) => {
            if (error.name === 'AbortError') {
              return
            }

            throw error
          })

        return () => {
          controller?.abort()
        }
      }

      return doAuthenticatedRequest(url, { method, data, controller })
    }

    if (cb) {
      if (!controller) {
        controller = new AbortController()
      }

      sendRequest(url, { method, data, controller })
        .then(async (res) => res.json())
        .then((res) => {
          controller = undefined
          cb(res)
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            return
          }

          throw error
        })

      return () => {
        controller?.abort()
      }
    }

    return sendRequest(url, { method, data, controller })
      .then(async (res) => res.json())
      .then((res) => {
        controller = undefined

        return res
      })
  }

  return {
    doRequest,
    authenticated,
  }
}
